<template>
  <div class="page-content">
    <div class="container-fluid">
      <DataTable
        :refs="refs"
        :colspan="10"
        :hasSearchFilter="true"
        :hasDateFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :hasExport="true"
        :addNew="addNew"
        :hasShowEntries="true"
        :hasSearch="true"
        :lblSearch="$t('app.search_by_name_or_email')"
        :fields="{
          id: 'id',
          name: 'name',
          email: 'email',
          mobile: 'mobile',
          date: 'date',
          status: 'status',
        }"
        :image="$t('view.image')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :hasName="true"
        :name="$t('view.username')"
        :hasEmail="true"
        :email="$t('view.email')"
        :hasCountryCode="false"
        :countryCode="$t('view.country_code')"
        :hasMobile="true"
        :mobile="$t('view.mobile')"
        :role_name="'member'"
        :hasDate="true"
        :hasTotalPaidInvoicesCount="true"
        :hasInvoices="true"
        :hasActions="true"
        :actionable="true"
        :sortable="true"
        v-on:hasAddNewPermission="handlePermissoinChange"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "users",
    };
  },
  watch: {
    //
  },
  created() {
    setCurrentPageTitle(this.$t("nav.users"), "bx bx-user");
  },
  methods: {
    handlePermissoinChange(event) {
      this.addNew = event;
    },
  },
});
</script>
